<template>
    <zj-scroll-list :title-list="titleList" :data-list="deviceList" @click="onClick"></zj-scroll-list>
</template>

<script>
import ajax from '@/ajax.js'
import XDate from '@/utils/x-date.js'
import ZjScrollList from 'zj-scroll-list'
export default {
    name: 'ComDevices',
    components: {
        ZjScrollList,
    },
    data(){
        return {
            titleList: [
                { flex: 5, title: '时间'},
                { flex: 5, title: 'ID'},
                { flex: 5, title: 'ping'},
                { flex: 2, title: '通道'},
                // { flex: 5, title: '名称'},
                { flex: 5, title: '温度'},
                { flex: 2, title: '电量'},
            ],
            devices: [
                {
                    id: '1',
                    title: '我是一个兵1',
                    phone: '13875102770',
                    lng: 120.10,
                    lat: 30.20,
                    address: '中国广东省韶关市仁化县丹霞大道118号',
                    is_active: false,
                    is_hover: false,
                    is_open: false
                },
            ],
        }
    },
    computed:{
        deviceList(){
            return this.devices.map(it => {
                let time = it.timestamp ? XDate.formatTimestamp('H:i:s', XDate.toTimeStamp(it.timestamp.replace('T', ' '))) : '';
                return {
                    key: it.id,
                    row: [
                        { text: time, tip: it.timestamp, },
                        { text: it.id, text_color: 'green', emit: 'click', },
                        { text: it.ping, },
                        { text: it.channel === 0 ? '热端' : it.channel === 1 ? '冷端' : '温度', tip: "channel: "+it.channel, text_color: it.channel === 0 ? 'red' : it.channel === 1 ? 'blue' : 'gray', },
                        // { text: "MTTU125F", emit: 'click', },
                        { text: it.temperature && it.temperature.toFixed(3)+"℃", tip: it.channel === 0 ? '热端温度' : it.channel === 1 ? '冷端温度' : '温度', text_color: it.channel === 0 ? 'red' : it.channel === 1 ? 'blue' : 'gray', },
                        { list: [
                            // { icon: require("@/static/icons/icon-hotspot-signal-5.svg"), icon_color: 'none', tip: "信号", emit: "signal" },
                            { icon: require("@/static/icons/icon-hotspot-power-0.svg"), icon_color: 'none', tip: it.battery,},
                        ] },
                    ],
                }
            });
        },
    },
    created(){
        this.download();
        window.setInterval(() => {
            this.download();
        }, 5000);
    },
    methods:{
        download(){
            ajax({url: '/api/overview'}).then(res => {
                // console.log('/api/overview:', res);
                this.devices = res
            });
        },
        update(){
            
        },
        
        onClick(e){
            this.abd = JSON.stringify(e);
        },
    },
    watch:{
    }
}
</script>